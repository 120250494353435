<template>
  <div v-if="checkPermission(['Inventory', 'PUT'])">
    <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">edit</i>
    </span>
    <div class="centerx">
      <vs-popup
        :id="'popmodal' + params.data.department_id"
        class="holamundo editModal"
        title="Update Inventory"
        :active.sync="popupActive"
      >
        <form>
          <FocusLock>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="col-12">
                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Expense Name</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-input
                        disabled
                        v-validate="'required'"
                        name="Expense Name"
                        v-model="form.expense_name"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Expense Name')"
                        >{{ errors.first("Expense Name") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Category</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-input
                        disabled
                        v-validate="'required'"
                        name="Category Name"
                        v-model="form.category_name"
                        class="w-full"
                        rules="alpha"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Category Name')"
                        >{{ errors.first("Category Name") }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Location</h5>
                  </div>
                  <div class="col-7">
                    <!-- Start ************* -->
                    <div>
                      <multiselect
                        v-model="city_value"
                        track-by="city_id"
                        label="city_name"
                        @input="({ city_id }) => (this.form.city_id = city_id)"
                        :options="cities"
                        name="City Name"
                        :searchable="true"
                        :deselectLabel="''"
                        :select-label="''"
                        :allow-empty="false"
                        open-direction="bottom"
                        deselect-label="Can't remove this value"
                      >
                        <!--   :disabled="inventory == '1' ? true : false" -->
                        <template slot="singleLabel" slot-scope="{ option }">{{
                          option.city_name
                        }}</template>
                      </multiselect>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('City Name')"
                        >{{ errors.first("City Name") }}</span
                      >
                    </div>
                    <!-- ************* End -->
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Number of Qty.</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-input
                        v-validate="'required|regex:^[0-9]+'"
                        name="Number of Qty "
                        type="number"
                        v-model="form.quantity"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Number of Qty')"
                        >{{ errors.first("Number of Qty") }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                  >Save</vs-button
                >

                <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button> -->
              </div>
            </div>
          </FocusLock>
        </form>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import departmentService from "@/services/departmentService.js";
import CountryService from "@/services/countryService.js";
import InventoryService from "@/services/inventoryService.js";
export default Vue.extend({
  data() {
    return {
      switch1: true,
      submitStatus: true,
      form: {
        expense_name: "",
        category_name: "",
        city_id: "",
        quantity: "",
      },
      companies: [],
      cities: [],
      inventory: "",
      city_value: [],
      company_value: [],
      department_value: [],
      popupActive: false,
      params: null,
      value: null,
      count: 0,
    };
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        if (this.count > 0) {
          this.submitStatus = false;
        }
        this.count++;
      },
      deep: true,
    },
  },
  beforeMount() {
    this.form = this.params.data;
    this.inventory = this.params.data.inventory_active;
    console.log(this.params, "inventory");
    this.inventory_id = this.params.value;
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodal" + this.params.data.inventory_id
    );
    // _id.setAttribute("style", "display: none;");
  },
  methods: {
    showModal() {
      this.popupActive = true;
      this.getAllCityList();
    },
    submitForm() {
      this.$validator.validateAll().then((result) => {
        this.$vs.loading();
        if (result) {
          let id = this.params.data.inventory_id;

          let payload = {
            project_id: this.params.data.project_id,
            project_name: this.params.data.project_name,
            category_id: this.params.data.category_id,
            category_name: this.params.data.category_name,
            expense_name: this.params.data.expense_name,
            quantity: this.form.quantity,
            city_id: this.form.city_id,
            expense_image: this.params.data.expense_image,
            inventory_active: this.params.data.inventory_active,
          };
          InventoryService.editInventory(payload, id)
            .then((response) => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              this.popupActive = false;

              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Updated!",
                  text: data.Message,

                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                if (this.params.data.inventory_active == 1) {
                  setTimeout(() => {
                    eventBus.$emit("refreshInvenoryTableList", data);
                  }, 1);
                } else {
                  setTimeout(() => {
                    eventBus.$emit("refreshInvenoryTable", data);
                  }, 1);
                }

                this.clearForm();
              } else {
                this.$vs.loading.close();
                console.log("data :", data);
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.submitStatus = true;
              console.log("error :", error);
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },

    getAllCityList: function () {
      CountryService.getAllCity()
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.cities = data.data;
            let SelectedCityDropdown = this.cities.filter(
              ({ city_id }) => String(city_id) === String(this.form.city_id)
            );
            this.city_value =
              SelectedCityDropdown.length > 0 ? SelectedCityDropdown[0] : [];
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    clearForm: function () {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.$validator.reset();
    },
  },

  mounted() {
    // this.getCompanyList();
  },
});
</script>
<style>
</style>
